<script setup lang="ts">
import type { DepositStreakInfo, Promotion } from "@/types";

const { t } = useT();
const { open } = useAppModals();

const props = defineProps<{
	depositInfo: DepositStreakInfo;
	finishedAt: string;
	image: string;
	type?: Promotion["type"];
}>();

const activeDepositCount = computed(() => props?.depositInfo?.progress?.lastIndexOf(true) + 1);
const depositsTotals = computed(() => props?.depositInfo?.progress?.length);

const handleClick = () => {
	if (props.depositInfo?.dailyWinStreakLimitExceeded) {
		return;
	}

	if (props.depositInfo?.canWheel) {
		open("LazyOModalDepositStreak");
		return;
	}

	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 135,
			height: 246,
			src: image,
			format: 'avif',
			loading: 'lazy',
			alt: 'race-promotion-img'
		}"
		width="328px"
		height="246px"
		class="deposit-promo"
		@click="handleClick"
	>
		<div class="deposit-promo-wrapper">
			<ABadge background="var(--goeteborg)" variant="info" autosize>
				<AText variant="ternopil" class="text-carabanchel badge" :modifiers="['bold', 'uppercase']">
					<i18n-t keypath="Purchase {key} times for a special prize!">
						<template #key>
							{{ depositInfo?.maxNumberOfDeposit }}
						</template>
					</i18n-t>
				</AText>
			</ABadge>
			<AText variant="topeka" class="deposit-promo-subtitle" :modifiers="['uppercase', 'bold']">
				{{ t("Up to % of your purchase", { msg: depositInfo?.maxPercentage }) }}
			</AText>
			<AText
				v-if="!depositInfo.dailyWinStreakLimitExceeded && !depositInfo?.canWheel"
				variant="topeka"
				:modifiers="['semibold']"
			>
				<i18n-t keypath="Ends in: {key}">
					<template #key>
						<AText variant="topeka" class="text-coro" gradient="var(--goeteborg)" :modifiers="['extrabold']">
							<span>{{ finishedAt }}</span>
						</AText>
					</template>
				</i18n-t>
			</AText>
			<MDepositStreakLevelsBar
				v-if="!depositInfo.dailyWinStreakLimitExceeded"
				:active="activeDepositCount"
				:levels="depositsTotals"
			/>
			<AButton variant="primary" :disabled="depositInfo.dailyWinStreakLimitExceeded" size="s">
				<AText :modifiers="['capitalize', 'semibold']" :class="['text-tlalnepantla', 'text-carabanchel']">
					<span v-if="!depositInfo.dailyWinStreakLimitExceeded">{{
						depositInfo.canWheel ? t("Get prize") : t("Open store")
					}}</span>
					<span v-else>{{ t("Available Tomorrow") }}</span>
				</AText>
			</AButton>
		</div>
	</ACard>
</template>

<style lang="scss" scoped>
.deposit-promo {
	padding: 16px;
	justify-content: center;
	background: var(--gadwal);

	&:deep(div.badge) {
		min-height: 22px;
		display: inline-flex;
		align-items: center;
	}

	&-wrapper {
		display: flex;
		flex-direction: column;
		gap: 8px;
		max-width: 239px;
	}

	&-subtitle {
		max-width: 173px;
	}

	button {
		min-width: 140px;
		margin-top: 4px;
		width: max-content;

		&:disabled {
			background: var(--chemnitz);
			cursor: not-allowed;

			span {
				color: var(--czestochowa);
			}
		}
	}
}
</style>
